import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular";


Sentry.init({
  dsn: "https://605451a6c4ac42ceb25e6d3085ce93e4@o4504294098796544.ingest.sentry.io/4504294101155840",
  release: environment.appVersion,
  enabled: environment.production,
  environment: environment.production ? 'Production' : 'Dev',
  integrations: [
    Sentry.browserTracingIntegration()
  ],
  tracePropagationTargets: ["localhost", /^https:\/\/mood-data\.s3\.eu-west-1.amazonaws.com/, /^https:\/\/mood-platform\.avia-gis.com/, /^https:\/\/app\.mood-h2020\.eu/],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


console.info(`Running platform version ${environment.appVersion}`);
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

