import { NgModule } from '@angular/core';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { ApplicationCoreModule } from './core/core.module';
import { HeaderModule } from './header/header.module';

import { enGB } from 'date-fns/locale';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { GeonetworkToolComponent } from './routes/external-tool/geonetwork-tool.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModellerGuard } from './core/auth/modeller.guard';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


const routes: Routes = [
  {
    path: 'core',
    loadChildren: () => import('./routes/core/core.module').then(m => m.CoreModule)
  },
  {
    path: 'tool',
    loadChildren: () => import('./routes/external-tool/external-tool.module').then(m => m.ExternalToolModule)
  },
  {
    path: "geonetwork/:id",
    component: GeonetworkToolComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./routes/admin/admin.module').then(m => m.AdminModule),
    canMatch: [ModellerGuard]
  },
  {
    path: '**',
    redirectTo: 'core',
    pathMatch: 'full'
  }
]

@NgModule({ declarations: [
        AppComponent,
        GeonetworkToolComponent
    ],
    bootstrap: [AppComponent], imports: [
        // Angular Modules
        CommonModule,
        // Feature modules
        ApplicationCoreModule,
        HeaderModule,
        MatSnackBarModule,
        RouterModule.forRoot(routes, { enableTracing: false })], providers: [
        {
            provide: MAT_DATE_LOCALE,
            useValue: enGB
        },
        {
            provide: DateAdapter,
            useClass: DateFnsAdapter,
            deps: [
                MAT_DATE_LOCALE
            ]
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            }
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 4000,
                horizontalPosition: 'end'
            }
        },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: {
                showDelay: 500,
                position: 'above'
            }
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    iconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );

    iconRegistry.addSvgIcon(
      'ecdc-logo',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/ecdc-logo.svg')
    );

    iconRegistry.addSvgIcon(
      'fao-logo',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/fao-logo.svg')
    );

    iconRegistry.addSvgIcon(
      'geonetwork-logo',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/geonetwork-logo.svg')
    );

    iconRegistry.addSvgIcon(
      'padiweb-logo',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/padiweb-logo.svg')
    );

    iconRegistry.addSvgIcon(
      'woah-logo',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/woah-logo.svg')
    );
  }
}
