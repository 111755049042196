import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mood-geonetwork-tool',
  templateUrl: './geonetwork-tool.component.html',
  styleUrls: [
    './geonetwork-tool.component.scss'
  ]
})
export class GeonetworkToolComponent implements OnInit {
  public iframeUrl!: SafeUrl;

  constructor(private _route: ActivatedRoute, private _sanitizer: DomSanitizer) { }

  private toolIdSub?: Subscription;

  ngOnInit(): void {
    this.toolIdSub = this._route.params.subscribe(params => {
      const toolId = params["id"];

      this._generateSafeUrl(toolId!);
    })
  }

  ngOnDestroy() {
    this.toolIdSub?.unsubscribe();
  }

  private _generateSafeUrl(toolId: string) {
    // let url: string = `https://geonetwork.mood-h2020.eu/geonetwork/srv/eng/catalog.search#/metadata/${toolId}`;
    let url: string = `https://mood-geonetwork.avia-gis.com/geonetwork/srv/eng/catalog.search#/metadata/${toolId}`;

    this.iframeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(url!);
  }
}
